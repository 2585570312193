/**
 * Aufzählung der möglichen Seitentypen.
 *
 * Diese wird benötigt, um die Art der Seite typsicher für das Feedback-Flyout zu übergeben.
 */
export enum PageType {
  Start = 'page-start',
  ResultList = 'page-result-list',
  ResultDetail = 'page-result-detail',
  FieldList = 'page-field-list',
  FieldDetail = 'page-field-detail'
}
